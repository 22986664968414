const menyer = [
  {
    id: 1,
    namn: "standard",
    mat: function () {
      return (
        <>
          <h3>Skärgårdstallrik</h3>
          Varmrökt lax, romsås, 3 sorters sill, gräddfil med gräslök,
          gubbröra, färskpotatis, västerbottenpaj, rågbröd, knäckebröd och smör
          – 195 kr

          <h3>Silltallrik</h3>3 sorters sill, gräddfil med gräslök, gubbröra,
          ägg, potatis, västerbottenpaj, rågbröd, knäckebröd och smör – 155 kr

          <h3>Paj med sallad</h3>
          Lax och spenat - 105kr
          <br />
          Fetaost och spenat - 105 kr

          <h3>Lasagne</h3>
          Serveras med sallad - 105 kr<br />
          Bolognese - 110 kr<br />
          Vegetarisk - 110 kr

          <h3>Smörrebröd</h3>
          <br />
          Kallrökt lax med citronkräm – 95 kr

          <h3>Grillsmörgås med sallad</h3>
          Ost och skinka med dijonsenap - 99 kr
          <br />
          Chevré, valnötter och honung - 99 kr

          <h3>Räksmörgås</h3>
          Handskalade stora räkor på rågbröd – 155 kr

          <h3>Våffla </h3>
          med grädde och sylt – 65 kr
          {/*
          <h3>Pannkakor</h3>
          med sylt och grädde, 2 eller 3 st. – 70/90 kr
          */}
        </>
      );
    },
  },
  {
    id: 2,
    namn: "start",
    mat: function () {
      return (
        <>

          <h3>Silltallrik</h3>3 sorters sill, gräddfil med gräslök, gubbröra,
          ägg, potatis, västerbottenpaj, rågbröd, knäckebröd och smör – 149 kr

          {/*
          <h3>Varmrökt lax med kokt potatis</h3>Romsås och sallad – 175 kr

          <h3>Portergryta</h3>
          på högrev, med vinbärsgele, potatis och sallad - 175 kr
         
          <h3>Räksoppa</h3>
          med handskalade räkor och halloumismörgås – 135 kr
       */}
          <h3>Paj med sallad</h3>
          Lax och spenat - 105 kr <br />
          Fetaost och spenat - 105 kr

          <h3>Räksmörgås</h3>
          Handskalade stora räkor på rågbröd – 155 kr

          <h3>Smörrebröd</h3>
          Kallrökt lax med citronkräm – 95 kr<br />

          {/*
          Rostbiff, potatis och remouladsås – 95 kr
        */}

          <h3>Grillsmörgås med sallad</h3>
          Ost och skinka med dijonsenap - 99 kr
          <br />
          Chevré, valnötter och honung - 99 kr

          {/*
          <h3>Lasagne</h3>
          Bolognese - 110 kr<br />
           Vegetarisk – 110 kr 
        */}

          {/*
          <h3>Våffla </h3>
          med grädde och sylt – 65 kr
        */}

          <h3>Pannkakor</h3>
          med sylt och grädde, 2 eller 3 st. – 70/90 kr

        </>
      );
    },
  },
  {
    id: 3,
    namn: "påsk",
    mat: function () {
      return (
        <>
          <h3>Påsktallrik</h3>
          3 sorters sill, rökt lax, gräddfil med gräslök, ägghalvor, potatis, västerbottenostpaj, rågbröd, knäckebröd och smör – 155 kr

          <h3>Paj med sallad</h3>

          {/* Lax och spenat - 110 kr <br /> */}
          Fetaost och spenat - 105 kr

          <h3>Räksmörgås</h3>
          Handskalade stora räkor på rågbröd – 155 kr

          <h3>Smörrebröd</h3>
          Kallrökt lax med citronkräm – 95 kr

          <h3>Grillsmörgås med sallad</h3>
          Ost och skinka med dijonsenap - 99 kr
          <br />
          Chevré, valnötter och honung - 99 kr

          <h3>Våffla </h3>
          med grädde och sylt – 65 kr
          {/*
          <h3>Pannkakor</h3>
          med sylt och grädde, 2 eller 3 st. – 70/90 kr
          */}

        </>
      );
    },
  },
  {
    id: 4,
    namn: "juli",
    mat: function () {
      return (
        <>
          <h3>Skärgårdstallrik</h3>Varmrökt lax, 3 sorters sill, gubbröra, västerbottenpaj, gräddfil med gräslök, färskpotatis, rågbröd, knäckebröd och smör – 195 kr

          {/*
          <h3>Varmrökt lax med fransk potatissallad</h3>Romsås och sallad – 175 kr
          */}

          <h3>Silltallrik</h3>3 sorters sill, gubbröra, västerbottenpaj, gräddfil med gräslök,
          ägg, färskpotatis, rågbröd, knäckebröd och smör – 149 kr

          {/*
          <h3>Räkssallad</h3>
          Handskalade lyxräkor, ägg, avokado och nobisdressing – 175 kr
          */}


          <h3>Paj med sallad</h3>
          Lax och spenat – 110 kr<br />
          Fetaost och spenat – 110 kr


          {/*
          <h3>Husets köttbullar</h3>
          med potatis, lingon, pressgurka och gräddsås  – 155 kr
          */}

          <h3>Lasagne med sallad</h3>
          Bolognese – 115 kr<br />
          Vegetarisk – 115 kr

          <h3>Räksmörgås</h3>
          Handskalade lyxräkor på rågbröd – 155 kr

          <h3>Smörrebröd</h3>
          Kallrökt lax med citronkräm – 95 kr

          <h3>Grillsmörgås med sallad</h3>
          Ost och skinka med dijonsenap – 99 kr<br />
          Chevré, valnötter och honung – 99 kr

          <h3>Barnmeny</h3>
          Pannkakor med sylt och grädde, 2 eller 3 st. – 70/90 kr
        </>
      );
    },
  },
  {
    id: 5,
    namn: "förenklad",
    mat: function () {
      return (
        <>
          <h3>Silltallrik</h3>3 sorters sill, gubbröra, västerbottenpaj, gräddfil med gräslök,
          ägg, färskpotatis, rågbröd, knäckebröd och smör – 155 kr

          <h3>Paj med sallad</h3>
          Lax och spenat - 105 kr
          <br />
          Fetaost och spenat - 105 kr

          <h3>Lasagne med sallad</h3>
          Bolognese – 105 kr<br />

          <h3>Räksmörgås</h3>
          Handskalade lyxräkor på rågbröd – 155 kr

          <h3>Smörrebröd</h3>
          Kallrökt lax med citronkräm – 95 kr

          <h3>Grillsmörgås med sallad</h3>
          Ost och skinka med dijonsenap - 99 kr
          <br />
          Chevré, valnötter och honung - 99 kr

          <h3>Barnmeny</h3>
          Pannkakor med sylt och grädde, 2 eller 3 st. – 60/80 kr
        </>
      );
    },
  },
  {
    id: 6,
    namn: "ingenlax",
    mat: function () {
      return (
        <>
          <h3>Silltallrik</h3>3 sorters sill, gubbröra, västerbottenpaj, gräddfil med gräslök,
          ägg, färskpotatis, rågbröd, knäckebröd och smör – 145 kr

          <h3>Paj med sallad</h3>
          Lax och spenat - 110 kr
          <br />
          Fetaost och spenat - 110 kr

          <h3>Lasagne med sallad</h3>
          Bolognese – 110 kr<br />

          <h3>Räksmörgås</h3>
          Handskalade lyxräkor på rågbröd – 155 kr

          <h3>Grillsmörgås med sallad</h3>
          Ost och skinka med dijonsenap - 110 kr
          <br />
          Chevré, valnötter och honung - 110 kr

          <h3>Barnmeny</h3>
          Pannkakor med sylt och grädde, 2 eller 3 st. – 70/90 kr
        </>
      );
    },
  },

  {
    id: 7,
    namn: "sensommar",
    mat: function () {
      return (
        <>

          {/*
         <h3>Kallrökt lax med dillstuvad potatis</h3> – 175 kr
          */}

          <h3>Husets köttbullar</h3>
          med potatis, lingonsylt, pressgurka och gräddsås  – 155 kr

          <h3>Silltallrik</h3>3 sorters sill, gubbröra, västerbottenpaj, gräddfil med gräslök,
          ägg, färskpotatis, rågbröd, knäckebröd och smör – 149 kr

          <h3>Paj med sallad</h3>
          Lax och spenat – 110 kr<br />
          Fetaost och spenat – 110 kr

          {/*
         <h3>Lasagne med sallad</h3>
          Bolognese – 115 kr<br />
          */}

          {/* 
          
          <h3>Räksmörgås</h3>
          Handskalade lyxräkor på rågbröd – 155 kr
          */}

          {/*
         <h3>Smörrebröd</h3>
          Kallrökt lax med citronkräm – 95 kr
          */}

          <h3>Köttbullesmörgås</h3>
          på tekaka med rödbetssallad – 85 kr

          <h3>Grillsmörgås med sallad</h3>
          Ost och skinka med dijonsenap – 99 kr<br />
          Chevré, valnötter och honung – 99 kr

          <h3>Barnmeny</h3>
          Pannkakor med sylt och grädde, 2 eller 3 st. – 70/90 kr
        </>
      );
    },
  },

  {
    id: 8,
    namn: "höst",
    mat: function () {
      return (
        <>

          <h3>Portergryta</h3>
          på högrev, med vinbärsgele, potatis och sallad - 175 kr

          <h3>Paj med sallad</h3>
          Lax och spenat - 110 kr
          <br />
          Fetaost och spenat - 110 kr

          <h3>Räksmörgås</h3>
          Handskalade lyxräkor på rågbröd – 155 kr

          <h3>Grillsmörgås med sallad</h3>
          Ost och skinka med dijonsenap - 99 kr

          <h3>Barnmeny</h3>
          Pannkakor med sylt och grädde, 2 eller 3 st. – 70/90 kr
        </>
      );
    },
  },

  {
    id: 9,
    namn: "jul",
    mat: function () {
      return (
        <>

          <h3>Jultallrik</h3>
          julskinka, kallrökt lax, 3 sorters sill, hovmästarsås, senap, rödbetssallad, gräddfil, gräslök, julost, potatis och vörtbröd - 155 kr.

          <h3>Paj med sallad</h3>
          Lax och spenat - 110 kr

          <h3>Grillsmörgås med sallad</h3>
          Ost och skinka med dijonsenap - 99 kr

          <h3>Smörrebröd</h3>
          Kallrökt lax med citronkräm – 95 kr

          <h3>Barnmeny</h3>
          Pannkakor med sylt och grädde, 2 eller 3 st. – 70/90 kr
        </>
      );
    },
  },

  {
    id: 10,
    namn: "midsommar",
    mat: function () {
      return (
        <>

          <h3>Midsommartallrik</h3>
          3 sorters sill, varmrökt lax, räkröra, gräddfil, gräslök, kokt ägg, potatis, smör och bröd - 195 kr

          <h3>Varmrökt lax med fransk potatissallad</h3>
          Romsås och sallad – 175 kr

          <h3>Räksmörgås</h3>
          Handskalade lyxräkor på rågbröd – 155 kr

          <h3>Smörrebröd</h3>
          Kallrökt lax med citronkräm – 95 kr

          <h3>Barnmeny</h3>
          Pannkakor med sylt och grädde, 2 eller 3 st. – 70/90 kr
        </>
      );
    },
  },
];

const dagens = menyer.filter((obj) => obj.namn === "sensommar")[0];

export default dagens;